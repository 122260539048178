import { useEffect } from 'react';

const useDeviceAdapter = () => {
  const setRootFontSize = () => {
    const deviceWidth = document.documentElement.clientWidth;
    const ratio = 16 / 375;
    const fontSize = deviceWidth > 1023 ? 16 : deviceWidth * ratio;

    /* 动态设置html的font-size */
    const root = window.document.querySelector('html');
    if (root) {
      root.style.fontSize = `${fontSize}px`;
    }
  };

  useEffect(() => {
    setRootFontSize();
    window.addEventListener('resize', setRootFontSize);

    return () => {
      window.removeEventListener('resize', setRootFontSize);
    };
  }, []);
};

export default useDeviceAdapter;
