import { createContext } from 'react';
import { User } from '@/request/interface/login';

interface AccountContextValue {
  account: User | null;
  clearAccount: () => void;
}

const initAccountContextData = {
  account: null,
  clearAccount: () => {},
};

export const AccountContext = createContext<AccountContextValue>(initAccountContextData);
