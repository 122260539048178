import queryString from 'query-string';

/**
 * 将对象转为URL查询参数
 *
 * @param {{ [key: string]: string }} obj 传入对象
 * @return {string} 查询参数
 */
export const objToQuery = (obj: { [key: string]: string }) => {
  const params = new URLSearchParams();

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(obj)) {
    if (!value) {
      // eslint-disable-next-line no-continue
      continue;
    }
    params.append(key, value);
  }

  const queryString = params.toString();

  return queryString ? `?${queryString}` : '';
};

/**
 *
 * @param url
 */
export const mergeQueryParams = (url: string) => {
  // eslint-disable-next-line no-script-url
  if (url?.startsWith('javascript:')) {
    return url;
  }
  const { l } = queryString.parseUrl(window.location.href).query || {};
  const targetUrlObj = queryString.parseUrl(url);
  // 和小棠沟通，如果带着 L 参链接进来，点击其它 L 参的链接，以带进来的 L 参为准
  const mergedQuery = { ...targetUrlObj.query, ...(l ? { l } : {}) };
  return queryString.stringifyUrl({ url: targetUrlObj.url, query: mergedQuery });
};

/**
 * 如果是 edu.net 的域名，返回 edu.net 的 API地址
 * @returns
 */
export const getAPIBaseUrl = (api: string | undefined) => {
  if (!api) {
    return '';
  }
  const isEduUrl = globalThis?.location?.href?.includes?.('.wukongedu.net');
  return isEduUrl ? api.replace('.wukongacademy.com', '.wukongedu.net') : api;
};
