import { ReactNode, useCallback, useMemo, useState } from 'react';
import { LoadingContext } from '@/context/LoadingContext';

export const LoadingProvider = ({ children }: { children: ReactNode }) => {
  const [show, setShow] = useState<boolean>(false);

  const memorizedHandleShowChange = useCallback((bool: boolean) => {
    setShow(bool);
  }, []);

  const memorizedValues = useMemo(() => {
    return { show, handleShowLoadingChange: memorizedHandleShowChange };
  }, [memorizedHandleShowChange, show]);

  return <LoadingContext.Provider value={memorizedValues}>{children}</LoadingContext.Provider>;
};
