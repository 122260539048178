import { createContext } from 'react';

interface LoadingContextStruct {
  show: boolean;
  handleShowLoadingChange: (b: boolean) => void;
}

export const LoadingContext = createContext<LoadingContextStruct>({
  show: false,
  handleShowLoadingChange: () => {},
});
