import { requestWkGateway as client } from '@/request/index';
import jsonwebtoken from 'jsonwebtoken';
import cacheStorage from '@/utils/cacheStorage';
import { TOKEN } from '@/utils/constants';
import { ReportCookieInfoReq } from './interface/agreement';

export type AgreementItem = {
  uuid: string;
  name: string;
  version: string;
  agreementType: 'STU_CENTER_USER_AGREEMENT' | 'STU_CENTER_PRIVACY_POLICY';
  content: string;
  contentZh: string;
  enabled: true;
  whenModified: string;
  whenCreated: string;
};
// 获取历史政策列表
export const getAgreementList = (params: { agreementTypes: [AgreementItem['agreementType']] }) => {
  return client.post<{ agreementTypes: [AgreementItem['agreementType']] }, { data: AgreementItem[] }>(
    `/ms-cms/public/agreement/list`,
    params,
  );
};

// 获取政策详情接口
export const getAgreementDetail = (params: { uuid: string }) => {
  return client.post<{ uuid: string }, { data: AgreementItem }>(`/ms-cms/public/agreement/detail`, params);
};

// 获取当前最新协议/政策
export const getAgreementLatest = (agreementType: AgreementItem['agreementType']) => {
  return client.post<AgreementItem['agreementType'], { data: AgreementItem }>(
    `/ms-cms/public/agreement/${agreementType}/latest`,
  );
};

/**
 * 上报同意cookie信息
 * @param data
 * @returns
 */
export const reportCookieInfo = (data: ReportCookieInfoReq) => {
  const token = cacheStorage.getItem(TOKEN);
  const decoded = jsonwebtoken.decode(token);
  const accountId = decoded?.sub;
  return client.post<any, any>(`/ms-user/api/agreement/user/batch/agreed`, {
    agreementTypes: ['STU_CENTER_COOKIE_POLICY'],
    accountId,
    consentId: data?.consentId,
    status: data?.totalStatus?.includes?.('=true') ? 'ACCEPT' : 'DENY',
    source: 'OFFICIAL_WEBSITE',
    context: data,
  });
};
