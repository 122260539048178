import { LoginType } from '@/request/interface/login';

const getVisiblePart = (number: string) => {
  const { length } = number;

  if (length <= 5) {
    return '*'.repeat(number.length);
  }

  const visiblePart = number.slice(0, 3) + '*'.repeat(length - 5) + number.slice(-2);
  return visiblePart;
};

// 手机号脱敏 保留前三 后2 其余脱敏，小于5全部脱敏
const desensitizePhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) {
    return ''; // 处理空字符串的情况
  }

  const hasAreaCode = phoneNumber.includes(' ');
  let maskedNumber = '';

  if (hasAreaCode) {
    const [areaCode, number] = phoneNumber.split(' ');
    const prefix = areaCode;
    const visiblePart = getVisiblePart(number);
    maskedNumber = `${prefix} ${visiblePart}`;
  } else {
    maskedNumber = getVisiblePart(phoneNumber);
  }

  return maskedNumber;
};

// 密码脱敏 保留前1 后1 其余脱敏，小于2全部脱敏
const desensitizePassword = (password: string) => {
  if (password.length <= 2) {
    return '*'.repeat(password.length); // 密码长度小于等于2，不脱敏
  }

  const firstChar = password.charAt(0);
  const lastChar = password.charAt(password.length - 1);
  const maskedChars = '*'.repeat(password.length - 2);

  return firstChar + maskedChars + lastChar;
};

//  邮箱脱敏 保留前3 小于3全部脱敏 只显示3个* 多余*不显示
const desensitizeEmailAddress = (emailAddress: string) => {
  const parts = emailAddress.split('@');
  const username = parts[0];
  const domain = parts[1];

  let maskedUsername = '';
  if (username.length <= 3) {
    maskedUsername = '*'.repeat(3);
  } else {
    maskedUsername = username.slice(0, 3) + '*'.repeat(3);
  }

  return `${maskedUsername}@${domain}`;
};

const isEmailAddress = (account: string) => {
  // 根据邮箱地址的特征进行判断，比如是否包含@
  return account.includes('@');
};

// 账户脱敏 邮箱&手机号
const desensitizeAccount = (account: string) => {
  if (isEmailAddress(account)) {
    return desensitizeEmailAddress(account);
  }
  return desensitizePhoneNumber(account);
};

export { desensitizePassword, desensitizeAccount };

/**
 * 判断第一次进入页面是否是学习园地页面
 *
 * @return {*}
 */
export const isFirstOfResources = () => {
  const firstPath = window.sessionStorage?.getItem('firstPath');
  return firstPath?.includes('/resources/');
};

/**
 * 记录用户最后一次登录方式，下次打开登录弹窗时使用该方式登录
 *
 * @param {LoginType} type 登录方式
 */
export const setLastLoginType = (type: LoginType) => {
  window.localStorage.setItem('lastLoginType', type);
};

/**
 * 获取用户最后一次登录方式，默认为 undefined
 */
export const getLastLoginType = (): LoginType | undefined => {
  const lastLoginType = window.localStorage.getItem('lastLoginType');
  return lastLoginType ? (lastLoginType as LoginType) : undefined;
};
