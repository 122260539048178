import http, { AxiosResponse } from 'axios';
import sha256 from 'crypto-js/sha256';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';
import Toast from '@/components/Toast';
import StuCenterToast from '@/components/StuCenterToast';
import { TOKEN } from '@/utils/constants';
import cacheStorage from '@/utils/cacheStorage';
import { removeTokenInfo } from '@/utils/token';
import { getAPIBaseUrl } from '@/utils/url';

export const publicApi = http.create({
  baseURL: getAPIBaseUrl(process.env.NEXT_PUBLIC_COMMON_API_HOST),
  timeout: 30e3,
});

export const request = http.create({
  baseURL: getAPIBaseUrl(process.env.NEXT_PUBLIC_INTEGRATION_API_HOST),
  timeout: 30e3,
});

export const requestEdm = http.create({
  baseURL: getAPIBaseUrl(process.env.NEXT_PUBLIC_INTEGRATION_API_HOST),
  timeout: 30e3,
});

export const gatewayRsRequest = http.create({
  baseURL: getAPIBaseUrl(process.env.NEXT_PUBLIC_GATEWAY_RS_API_HOST),
  timeout: 30e3,
});

export const requestStu = http.create({
  baseURL: getAPIBaseUrl(process.env.NEXT_PUBLIC_STU_CENTER_API_HOST),
  timeout: 30e3,
});

export const requestWkGateway = http.create({
  baseURL: getAPIBaseUrl(process.env.NEXT_PUBLIC_WK_GATEWAY_API_HOST),
  timeout: 30e3,
});

declare module 'axios' {
  interface AxiosRequestConfig {
    skipErrorHandler?: boolean;
    skipTransformResponse?: boolean;
    needAuth?: boolean;
    signature?: boolean;
    timestamp?: number | undefined;
  }
}

const responseSuccess = (res: AxiosResponse<any>) => {
  const { config } = res || {};
  if (config.skipTransformResponse) {
    return res.data;
  }

  return res.data;
};

const responseFail = (err: any) => {
  const { response, config, message: systemMessage, isAxiosError } = err;
  if (!isAxiosError || !response) {
    Toast.info(systemMessage);
  }
  if (err?.response) {
    const { status, data = {} } = response;
    const { errors, message } = data;
    const errMsg = (errors && errors[Object.keys(errors)[0]]) || message || err.message;

    if ((+status === 422 || +status === 403 || +status === 400) && !config?.skipErrorHandler) {
      const clearError = errMsg || '未知错误';
      Toast.info(clearError);
      throw new Error(`[Server Response Error]: ${clearError}`);
    }
    if (+status === 401) {
      removeTokenInfo();
    }

    if (+status !== 401 && !config?.skipErrorHandler && (typeof data === 'string' || errMsg)) {
      Toast.info(errMsg);
    }
  }

  throw err;
};

// 设置 error handler
request.interceptors.response.use(
  (res) => {
    return responseSuccess(res);
  },
  (err) => {
    return responseFail(err);
  },
);

publicApi.interceptors.response.use(
  (res) => {
    return responseSuccess(res);
  },
  (err) => {
    return responseFail(err);
  },
);

request.interceptors.request.use((requestConfig) => {
  const { headers, signature = false, data, url, method, timestamp } = requestConfig;
  if (localStorage.getItem('Accept-Language')) {
    headers.common['Accept-Language'] = localStorage.getItem('Accept-Language') === 'en' ? 'en-US' : 'zh-CN';
  }
  if (signature && timestamp) {
    // TODO 支持 请求头参数自定义
    const bodyHash = Base64.stringify(sha256(JSON.stringify(data)));
    // POST可以小写吗
    const methodNew = method?.toUpperCase();
    const secret =
      process.env.NEXT_PUBLIC_STAGE === 'prod'
        ? '7sjNcisfA6sb3T+OwPpCbBwpJt1VHfU5'
        : 'EkFY6JnQQ1Yn/vyD+KzXbGXlg3ivEJNL';
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const request = `${methodNew}\n${url}\n\ntimestamp:${timestamp}\n\ntimestamp\n${bodyHash}`;
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const signature = Base64.stringify(hmacSHA256(request, secret));
    headers.common.signature = signature;
    headers.common.SignedHeaders = 'timestamp';
    headers.common.timestamp = timestamp;
  }
  return requestConfig;
});

// 设置 Edm 不提示 Toast
requestEdm.interceptors.response.use(
  (res) => {
    return responseSuccess(res);
  },
  (err) => {
    return err;
  },
);

requestWkGateway.interceptors.request.use((requestConfig) => {
  const { headers, needAuth } = requestConfig;

  // 手动说明。兼容原来
  if (needAuth) {
    const token = cacheStorage.getItem(TOKEN);
    headers.common.Authorization = `Bearer ${token}`;
  }
  return requestConfig;
});

gatewayRsRequest.interceptors.request.use((requestConfig) => {
  const { headers } = requestConfig;
  // if (needAuth) {
  //   const token = localStorage.getItem('authToken');
  //   headers.common.Authorization = `Bearer ${token}`;
  // }
  const token = cacheStorage.getItem(TOKEN);
  if (token) {
    headers.common.Authorization = `Bearer ${token}`;
  }
  return requestConfig;
});

gatewayRsRequest.interceptors.response.use(
  (res) => {
    return responseSuccess(res);
  },
  (err) => {
    return responseFail(err);
  },
);

requestStu.interceptors.request.use((requestConfig) => {
  const { headers, signature = false, timestamp, method, data } = requestConfig;
  const token = cacheStorage.getItem(TOKEN);
  if (token) {
    headers.common.Authorization = `Bearer ${token}`;
  }
  headers.common.platform = 'web';
  headers.common['Accept-Language'] = window?.location?.href?.includes?.('/zh/') ? 'zh-CN' : 'en-US';
  if (signature && timestamp && data.captchaVerification) {
    // TODO 支持 请求头参数自定义
    const bodyHash = Base64.stringify(sha256(JSON.stringify({ captchaVerification: data.captchaVerification })));
    const methodNew = method?.toUpperCase();
    const veriUrl = '/api/jina/captcha/verify';
    const request = `${methodNew}\n${veriUrl}\n\ntimestamp:${timestamp}\n\ntimestamp\n${bodyHash}`;
    const signature = Base64.stringify(hmacSHA256(request, '*6#SGbQz7%s+E6)b`I6!8G"1u0Zv'));
    headers.signature = signature;
    headers.SignedHeaders = 'timestamp';
    headers.timestamp = timestamp;
  }
  return requestConfig;
});

requestStu.interceptors.response.use(
  (res) => {
    return responseSuccess(res);
  },
  (err) => {
    return responseFail(err);
  },
);

/*
 * fixme
 * */

const responseFailForClient = (err: any) => {
  const { response, config, message: systemMessage, isAxiosError } = err;
  if (!isAxiosError || !response) {
    StuCenterToast.error(systemMessage);
  }
  if (err?.response) {
    const { status, data = {} } = response;
    const { errors, message } = data;
    const errMsg = (errors && errors[Object.keys(errors)[0]]) || message || err.message;

    if ((+status === 422 || +status === 403 || +status === 400) && !config?.skipErrorHandler) {
      const clearError = errMsg || '未知错误';
      StuCenterToast.warning(clearError);
      throw new Error(`[Server Response Error]: ${clearError}`);
    }

    if (+status !== 401 && !config?.skipErrorHandler && (typeof data === 'string' || errMsg)) {
      StuCenterToast.warning(errMsg);
    }
  }

  throw err;
};

export const clientApiAxiosInstance = http.create({
  baseURL: getAPIBaseUrl(process.env.NEXT_PUBLIC_STU_CENTER_API_HOST),
  timeout: 30e3,
});

clientApiAxiosInstance.interceptors.request.use((requestConfig) => {
  const { headers } = requestConfig;
  const token = cacheStorage.getItem(TOKEN);
  if (token) {
    headers.common.Authorization = `Bearer ${token}`;
  }
  headers.common.platform = headers?.common?.platform || 'web';
  headers.common['Accept-Language'] = window?.location?.href?.includes?.('/zh/') ? 'zh-CN' : 'en-US';
  return requestConfig;
});

clientApiAxiosInstance.interceptors.response.use(
  (res) => {
    return responseSuccess(res);
  },
  (err) => {
    return responseFailForClient(err);
  },
);
