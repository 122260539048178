// eslint-disable-next-line import/no-anonymous-default-export
export default {
  // 纯数字手机号
  StandardMobile: /^[0-9]+$/,
  // 数字+短横线+空格
  Mobile: /^[0-9-\s]+$/,
  // 短横线与空格
  Hyphen: /[-|\s]/g,
  // 短横线
  Dash: /-/g,
  // 空格
  Blank: /[\s]/g,
};
