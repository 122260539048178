/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/prefer-default-export
import { parse } from 'qs';
import { StudentInfo } from '@/request/interface/login';
// eslint-disable-next-line import/no-cycle
import router from 'next/router';
import Cookie from 'js-cookie';
import { setSameDomain } from './token';

export const isProduction = () => process.env.NEXT_PUBLIC_STAGE === 'prod';
// eslint-disable-next-line import/no-cycle

// eslint-disable-next-line import/prefer-default-export
export const generateId = (): any => {
  const cookieId = localStorage.getItem('cookie_id');
  if (!cookieId) {
    const newCookieId = `cookie_id${new Date().getTime()}`;
    localStorage.setItem('cookie_id', newCookieId);
  }
  return cookieId;
};

export const getQueryString = (name: string): any => {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
  const r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return null;
};

// 获取最大和最小的 随机整数
export const randomIntFromInterval = (min: number, max: number): number => {
  // min和max为范围的最小值和最大值
  return Math.floor(Math.random() * (max - min + 1) + min);
};

interface Env {
  prod: string;
  test: string;
  develop: string;
}

// const envOrigin = {
//   prod: 'https://www.wukongsch.com',
//   test: 'https://wwww-test.wukongedu.net',
//   develop: 'https://wwww-dev.wukongedu.net',
// };

const stuEnvOrigin = {
  prod: 'https://student.wukongsch.com',
  prod_edu: 'https://student.wukongedu.net',
  test: 'https://student-test.wukongedu.net',
  develop: 'https://student-dev.wukongedu.net',
};

export const getStuCenterHost = (from?: string) => {
  const fromParam = from || 'newofficial_website';
  const lang = window.location.href.includes('/zh/') ? 'zh' : 'en';
  let origin = stuEnvOrigin[(process.env.NEXT_PUBLIC_STAGE as keyof Env) || 'develop'];
  if (process.env.NEXT_PUBLIC_STAGE === 'prod' && window.location.hostname.includes('wukongedu.net')) {
    origin = stuEnvOrigin.prod_edu;
  }
  return { origin, lang, fromParam };
};

export const getStuCenterUrl = (from?: string, router?: string, hasQuery?: boolean, notNeedFromParam?: boolean) => {
  setSameDomain();
  const { origin, lang, fromParam } = getStuCenterHost(from);
  const url = `${origin}/${lang}/${router || ''}`;
  if (notNeedFromParam) {
    return url;
  }
  return `${url}${hasQuery ? '&' : '?'}from=${fromParam}`;
};

export const isIOS = () => {
  const { userAgent } = navigator;
  return /iPhone|iPad|iPod/i.test(userAgent);
};

export const getLoginJumpUrl = (from?: string, notNeedFromParam?: boolean) => {
  const fromParam = from || 'newofficial_website';
  const { return_url: returnUrl } = parse(window?.location?.search?.slice(1) || '');
  if (returnUrl) {
    setSameDomain();
    const decodeUrl = decodeURIComponent(returnUrl as string);
    // 如果是学员嵌入互动作业地址，直接跳学员中心首页
    if (decodeUrl.includes('/iexercise?')) {
      return getStuCenterUrl('', '', false, notNeedFromParam);
    }

    const lang = window.location.href.includes('/zh/') ? 'zh' : 'en';

    // 如果returnUrl上无语言信息，需要强当前语言信息加上
    // if (!decodeUrl.includes(`/en`) && !decodeUrl.includes(`/zh`)) {
    //   const origin =
    //     isProduction() && window.location.hostname.includes('wukongsch.com') ? 'wukongsch.com' : 'wukongedu.net';
    //   decodeUrl = decodeUrl.replace(`${origin}`, `${origin}/${lang}`);
    // }
    // 不管 returnUrl 上有语言信息，还是无语言信息，都需要加上当前语言信息
    const { origin, pathname, search } = new URL(decodeUrl);
    const path = pathname?.replace(/(\/zh)|(\/en)/, '');
    const newHref = `${origin}/${lang}${path}${search}`;
    if (notNeedFromParam) {
      return newHref;
    }
    if (newHref.includes('?')) {
      return newHref.includes('from=') ? newHref : `${newHref}&from=${fromParam}`;
    }
    return `${newHref}?from=${fromParam}`;
  }
  return getStuCenterUrl('', '', false, notNeedFromParam);
};

export const updateIntercomInfo = (currentStudent: StudentInfo) => {
  if (currentStudent?.uuid && currentStudent?.hmacSHA256AccountId) {
    const language = window.location.href.includes('/zh/') ? 'ZH' : 'EN';
    const {
      uuid,
      fullName,
      hmacSHA256AccountId,
      data,
      chinesePayStatus,
      englishPayStatus,
      mathPayStatus,
      country,
      code,
      payStatus,
    } = currentStudent || {};
    // 为 intercom 客服系统设置登录态
    const subjects = [];
    if (chinesePayStatus === 'PAID') {
      subjects.push('Chinese');
    }
    if (englishPayStatus === 'PAID') {
      subjects.push('ELA');
    }
    if (mathPayStatus === 'PAID') {
      subjects.push('Math');
    }
    window?.Intercom?.('update', {
      user_id: uuid,
      name: fullName,
      Country: country,
      City: data?.city,
      Subject: subjects.join(','),
      StudentCode: code,
      Language: language,
      PayStatus: payStatus,
      user_hash: hmacSHA256AccountId,
    });
  }
};

const pathName: { [key: string]: string } = {
  '/': 'HomePage',
  '/why-wukong': 'How',
  '/chinese': 'ChineseCourse',
  '/math': 'MathCourse',
  '/english': 'EnglishCourse',
  '/download': 'APPDownload',
};

export const getGa4PageName = (path: string) => {
  const defaultPageName = 'NotFoundPage'; // 默认页面名称

  // 检查给定的路径是否在 pathName 对象中，并且对应的值不为 undefined
  if (pathName[path] !== undefined) {
    return pathName[path]; // 返回对应的页面名称
  }
  return defaultPageName; // 返回默认页面名称
};

export const isChrome = () => /Chrome/.test(navigator.userAgent) && !/Edg/.test(navigator.userAgent);

export const hideIntercomUrls = [
  'terms',
  'policy',
  'independent-appointment',
  'agreement',
  'student-center-login',
  'app/download',
];

/**
 *通过url获取文件名
 *
 * @param {string} url
 * @return {*}  {string}
 */
export const getFileNameFromUrl = (url: string): string => {
  const lastSlashIndex = url?.lastIndexOf?.('/');
  const fileName = url?.substring?.(lastSlashIndex + 1);
  return decodeURIComponent(fileName);
};

/**
 * PC端下载文件
 * @param {String} path - 下载地址/下载请求地址。
 * @param {String} fileName - 下载文件的名字（考虑到兼容性问题，最好加上后缀名）
 */
// export const downloadFileForPC = (url: string, fileName?: string) => {
//   fetch(url)
//     .then((response) => response.blob())
//     .then((blob) => {
//       const link = document.createElement('a');
//       link.href = URL.createObjectURL(blob);
//       link.download = fileName || getFileNameFromUrl(url);
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//     });
// };

/**
 * 下载文件
 * @param {String} path - 下载地址/下载请求地址。
 */
export const downloadFile = (url: string) => {
  const link = document.createElement('a');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * 将单词首字母变为大写
 *
 * @param {string} string
 * @return {*}
 */
export const capitalizeFirstLetter = (string: string) => {
  // 如果字符串为空，则返回空字符串
  if (!string) {
    return '';
  }

  // 将字符串的第一个字母转换为大写，并将其与字符串的其余部分连接起来
  return string[0].toUpperCase() + string.slice(1);
};

/**
 * 将秒数转换为时分秒格式
 *
 * @param {number} seconds
 * @return {*}
 */
export const formatSecondsToTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  if (formattedHours === '00') {
    return `${formattedMinutes}:${formattedSeconds}`;
  }

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

/**
 * 根据视频URL获取视频时长
 *
 * @param {string} fileUrl
 * @param {(time: string) => void} callback
 */
export const getVideoTime = (fileUrl: string, callback: (time: string) => void) => {
  const video = document.createElement('video');
  video.src = fileUrl as string;
  video.addEventListener('loadedmetadata', () => {
    if (!video?.duration) {
      return;
    }
    const time = formatSecondsToTime(video.duration);
    callback(time);
  });
};

/**
 * 根据音频URL获取音频时长
 *
 * @param {string} fileUrl
 * @param {(time: string) => void} callback
 */
export const getAudioTime = (fileUrl: string, callback: (time: string) => void) => {
  const audio = new Audio();
  audio.src = fileUrl;

  audio.addEventListener('loadedmetadata', () => {
    if (!audio?.duration) {
      return;
    }
    const time = formatSecondsToTime(audio.duration);
    callback(time);
  });
};

/**
 * 判断是否是PC设备
 *
 */
export const isPC = (userAgent?: string) =>
  !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    userAgent || globalThis?.navigator?.userAgent || '',
  );

/**
 * 对象数组去重
 *
 * @param {any[]} array 需要去重的数组
 * @param {string} keyName 去重属性，当这个属性相等是默认是重复项
 * @return {*}
 */
export const deduplicateArray = (array: any[], keyName: string) => {
  const uniqueArray = array.filter((item, index) => {
    const foundIndex = array.findIndex((element, idx) => {
      return element[keyName] === item[keyName] && idx < index;
    });
    return foundIndex === -1;
  });
  return uniqueArray;
};

export const getIsAndroid = (): boolean => {
  const u = window?.navigator.userAgent;
  return u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
};

export const checkBrowerType = () => {
  return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1;
};

export const copyToClipboard = (str: string) => {
  return new Promise((resolve, reject) => {
    if (str === '') {
      reject(new Error('没有复制内容'));
    }
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    resolve(true);
  });
};

export const getAppPlatform = () => {
  const { userAgent } = navigator;
  const isAndroid = /Android/i.test(userAgent);
  const isTablet = /Android/i.test(userAgent) && /Mobile/i.test(userAgent) === false;
  const isIPhone = /iPhone/i.test(userAgent);
  const isIPad = /iPad/i.test(userAgent);
  if (isAndroid || isTablet) {
    return 'ANDROID';
  }
  if (isIPhone || isIPad) {
    return 'IOS';
  }
  return 'WEB';
};

export const formatAppEnv = (appEnv: string) => {
  const appEnvMap = {
    app_ios: 'IOS',
    app_android: 'ANDROID',
  };
  return appEnvMap?.[appEnv as 'app_ios' | 'app_android'] || 'WEB';
};

// 检索ga或fb渠道
// eslint-disable-next-line consistent-return
export const pixelChannel = (id: any) => {
  if (id === '79ad83aa-a0ab-45c7-addb-8d72f73f318f') {
    return 'GA';
  }
  if (id === '17cd3889-4876-43a8-b3c8-c0f8d08c655d') {
    return 'PIXEL';
  }
  // 喜马拉雅
  if (id === 'ad01e1a2-bd34-42f2-9df9-189d9d579f01') {
    return 'XMLY';
  }
  // pintrk
  if (id === '9289f76b-366d-4ef6-b00c-533125cfcd6c') {
    return 'PINTEREST';
  }
  // Bing
  if (id === '050c24b6-6534-42ba-a39d-7c445aed7a36') {
    return 'BING';
  }
  if (id === 'e631acc0-de40-4185-ae21-57986a2f6513') {
    return 'SHARE_A_SALE';
  }
  if (id === '2b96e9d0-6ecb-4634-8f05-3c33a84c7406') {
    return 'Taboola';
  }
  if (id === 'ea8db6c1-f9b1-4353-8339-c62282d70017') {
    return 'Twitter';
  }
  if (id === 'ce980e9d-6018-4a8e-b301-d764b820b5f3') {
    return 'TikTok';
  }
  return 'other';
};

// 判断各种类型是否为空
export const isEmpty = (value: any): boolean => {
  // null 或者 未定义，则为空
  if (value === null || value === undefined) {
    return true;
  }
  // 传入空字符串，则为空
  if (typeof value === 'string') {
    return value === '';
  }
  // 传入数组长度为0，则为空
  if (value instanceof Array) {
    return !value.length;
  }
  // 传入空对象，则为空
  if (value instanceof Object) {
    return !Object.keys(value).length;
  }
  return false;
};

// 概率算法
function randomProbability(chance: number): boolean {
  return Math.random() < chance / 100; // 0.1 表示 10% 的概率
}

const goToGS20page = (queryString?: string) => {
  router.push(`/experienceClassBooking${queryString || ''}`);
  return true;
};

const shouldCheckGS20 = (subject: string | undefined) => {
  return subject === 'chinese';
};

const shouldCheckLanguage = () => {
  return window?.location?.pathname?.startsWith('/zh');
};

const handleLoggedInUser = (student: StudentInfo, querystring?: string) => {
  if (student.gs20User) {
    return goToGS20page(querystring);
  }
  return false;
};
const getCachedVersion = () => {
  return Cookie.get('FOR_GS20_TYPE');
};

const handleCachedVersion = (cachedVersion: string, querystring?: string) => {
  if (cachedVersion === 'true') {
    return goToGS20page(querystring);
  }
  return false;
};

const splitTrafficAndCache = (querystring?: string) => {
  const isGS20 = randomProbability(40);
  Cookie.set('FOR_GS20_TYPE', isGS20 ? 'true' : 'false', { expires: 7 });

  if (isGS20) {
    return goToGS20page(querystring);
  }
  return false;
};

const handleGuestUser = (querystring?: string) => {
  const cachedVersion = getCachedVersion();

  if (cachedVersion) {
    return handleCachedVersion(cachedVersion, querystring);
  }

  return splitTrafficAndCache(querystring);
};

// 判断是否要跳转到GS2.0落地页
/**
 * AB Test 方案 @骆曦（Viva） @Kenny Yang（Kenny） @赵丹妮（Danielle）
 * AB 规则仅在英文语言环境下生效
 * 已登录用户， 判断学生 GS2.0 标签
 * 如果有 GS2.0 标签，则走 GS2.0 约课流程
 * 如果没有，则固定走GS1.0 约课流程
 * 未登录用户，判断当前是否已经有缓存分流版本
 * 如果有，则走缓存的分流版本
 * 如果没有，则根据分流比例，按概率进行分流，同时缓存当前的分流版本结果
 */

export const checkIfGoToGS20 = (
  subject: 'chinese' | 'math' | 'english' | undefined | '',
  student: StudentInfo | undefined,
  querystring?: string,
) => {
  if (shouldCheckLanguage()) return false;

  if (!shouldCheckGS20(subject)) return false;

  if (student) {
    return handleLoggedInUser(student as StudentInfo, querystring);
  }

  return handleGuestUser(querystring);
};
