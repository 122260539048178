import React from 'react';
import { LoginBtnReportParams, StudentInfo } from '@/request/interface/login';

export interface ILogin {
  subject?: 'CHINESE' | 'MATH' | 'ENGLISH';
  source?: string;
  triggerEvent?: string;
  isPhoneLogin?: boolean;
  loginFinishCallback?: () => void;
  phone?: string;
  leadId?: string;
  forceOpenPCLogin?: boolean;
}

export interface IGlobalLoginContext {
  handleLogin: (params?: ILogin | undefined) => void;
  loginPCVisible: boolean;
  loginMobileVisible: boolean;
  setLoginPCVisible: (visible: boolean) => void;
  setLoginMobileVisible: (visible: boolean) => void;
  loginPCPhoneVisible: boolean;
  setLoginPCPhoneVisible: (visible: boolean) => void;
  logoutDrawerVisible: boolean;
  setLogoutDrawerVisible: (visible: boolean) => void;
  student?: StudentInfo;
  handleLoginFinish: () => void;
  getStudents: (loginBtnReportParams?: LoginBtnReportParams) => void;
  handleLogout: () => void;
  loginParams?: ILogin;
  handleGoToStuCenter: (openInCurrentTab?: boolean) => void;
  handleCustomLoginFinish: () => void;
  isLogin: boolean;
}

const initValues = {
  handleLogin: () => {},
  loginPCVisible: false,
  loginMobileVisible: false,
  setLoginPCVisible: () => {},
  setLoginMobileVisible: () => {},
  loginPCPhoneVisible: false,
  setLoginPCPhoneVisible: () => {},
  logoutDrawerVisible: false,
  setLogoutDrawerVisible: () => {},
  handleLoginFinish: () => {},
  getStudents: () => {},
  handleLogout: () => {},
  handleGoToStuCenter: () => {},
  isLogin: false,
  handleCustomLoginFinish: () => {},
};

export const GlobalLoginContext = React.createContext<IGlobalLoginContext>(initValues);
