import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import ReactDOM from 'react-dom';
import cls from 'classnames';
import Image from 'next/image';

import iconSuccess from '@public/images/toast/icon-success-toast.svg';
import iconError from '@public/images/toast/icon-err-toast.svg';
import iconPrimary from '@public/images/toast/icon-primary.png';
import iconWarning from '@public/images/toast/icon-warn-toast.svg';
import styles from './style.module.scss';

const IconMap = {
  error: iconError,
  success: iconSuccess,
  primary: iconPrimary,
  warning: iconWarning,
};

export interface ToastProps {
  type?: 'error' | 'warning' | 'success' | 'primary';
  duration?: number;
  showIcon?: boolean;
  className?: string;
  content?: React.ReactNode | string;
  onClose?: void;
  onExited?(): void;
}

const DEFAULT_DURATION = 3000;

const ToastComponent = (props: Omit<ToastProps, 'onClose'>) => {
  const { type = 'primary', showIcon = true, className, content, onExited, duration = DEFAULT_DURATION } = props;
  const [inProp, setInProp] = useState(false);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    setInProp(true);
    const timer = setTimeout(() => {
      setInProp(false);
    }, duration);
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      timer && clearTimeout(timer);
    };
  }, [duration]);

  return ReactDOM.createPortal(
    <CSSTransition
      timeout={600}
      in={inProp}
      onExited={onExited}
      classNames={{
        enter: styles.toastEnter,
        enterActive: styles.toastEnterActive,
        enterDone: styles.toastEnterDone,
        exit: styles.toastEnter,
        exitActive: styles.toastExit,
      }}
      onEntering={() => setShowContent(true)}
    >
      <div className={styles.toast}>
        <div className={cls(styles.toastContainer, styles[`toast-container-${type}`], className)}>
          {showIcon && (
            <div className={styles.toastIcon}>
              <Image quality={100} src={IconMap[type]} alt='toast' />
            </div>
          )}
          <div className={cls({ [styles.toastWrapper]: true, [styles.showContent]: showContent })}>{content}</div>
        </div>
      </div>
    </CSSTransition>,
    document?.body,
  );
};

const Toast = (props: ToastProps) => {
  const div = document?.createElement('div');
  ReactDOM.render(<ToastComponent {...props} onExited={() => ReactDOM.unmountComponentAtNode(div)} />, div);

  return {
    destory(): void {
      ReactDOM.unmountComponentAtNode(div);
    },
  };
};

const info = (content: ToastProps['content'], props?: Omit<ToastProps, 'type' | 'content'>) =>
  Toast({ type: 'primary', content, ...props });

const success = (content: ToastProps['content'], props?: Omit<ToastProps, 'type' | 'content'>) =>
  Toast({ type: 'success', content, ...props });

const warning = (content: ToastProps['content'], props?: Omit<ToastProps, 'type' | 'content'>) =>
  Toast({ type: 'warning', content, ...props });

const error = (content: ToastProps['content'], props?: Omit<ToastProps, 'type' | 'content'>) =>
  Toast({ type: 'error', content, ...props });

export default {
  info,
  success,
  error,
  warning,
};
