import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AccountContext } from '@/context/AccountContext';
import { getAccountInfo } from '@/request/login';
import { GlobalLoginContext } from '@/context/GlobalLoginContest';

export const AccountProvider = ({ children }: { children: ReactNode }) => {
  const [account, setAccount] = useState<any>(null);
  const { isLogin } = useContext(GlobalLoginContext);

  const getAccount = () => {
    getAccountInfo().then((res) => {
      setAccount(res);
    });
  };

  const memorizedClearAccount = useCallback(() => {
    setAccount(null);
  }, []);

  useEffect(() => {
    if (isLogin) {
      setTimeout(() => {
        getAccount();
      }, 0);
    }
  }, [isLogin]);

  const memorizedValues = useMemo(() => {
    return { account, clearAccount: memorizedClearAccount };
  }, [account, memorizedClearAccount]);

  return <AccountContext.Provider value={memorizedValues}>{children}</AccountContext.Provider>;
};
