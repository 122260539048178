import { ReactNode } from 'react';
import { AccountProvider } from '@/providers/AccountProvider';
import { GlobalLoginProvider } from '@/providers/GlobalLoginProvider';
import { LoadingProvider } from '@/providers/LoadingProvider';

export const Providers = ({ children }: { children: ReactNode }) => {
  return (
    <LoadingProvider>
      <GlobalLoginProvider>
        <AccountProvider>{children}</AccountProvider>
      </GlobalLoginProvider>
    </LoadingProvider>
  );
};
